@import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;  
}
html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

:root {
   --black: #252627;
   --primary:#5569c1;
  /* --primary: #a60ee1; */
 
  --secondary:#5569c1;
--primary2:#ab9cf4;
--primary3:#dad0f5;
--darkblue:#111b3a;
  --white: #fefefe;
  --grey:#f3f3f3;
}

a {
  text-decoration: none;
}

ul {
  padding-left: 0px;
}
p{
  margin-bottom: 0px;
}
.text {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 22px;
  color: #424242;
  font-weight: 400;
}
.head{
font-size: 13px;
color:var(--primary);
text-align: center;
font-weight: 700;
}
.sub-head{
  font-size: 29px;
  color: #545454;;
  text-align: center;
  font-weight: 700;
}
/* styles.css */
.navbar-toggler {
  /* border:transparent */
  border: 0.3px solid red
}

.navlogo {
  font-size: 1.9rem;
}
@media only screen and (max-width: 1024px) {
  .navlogo {
    font-size: 1.5rem;
  }
}
.nav-items {
  margin: auto;
  gap: 30px;
  position: relative;
  right: 56px;
  font-weight: 600;
}
.nav-items a:hover{
  font-weight: bold;
}
@media only screen and (max-width: 993px) {
  .nav-items {
    /* margin: 5px; */
    gap: 10px;
    text-align: center;
    right: 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .responsive_nav {
    transform: translateY(100vh);
  }
}


/* button */

/* CSS */
.button-1 {
  align-items: center;
  background-color: var(--primary);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 540px;
  min-height: 50px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}


.button-1:active {
  background: var(--secondary);
  color: rgb(255, 255, 255, .7);
}

.button-1:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}
   /* button 2 */

   .button-2 {
    align-items: center;
    background-color: var(--white);
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color:  var(--primary);

    cursor: pointer;
    display: inline-flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 540px;
    min-height: 50px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
  }
  
  .button-2:hover,
  .button-2:focus { 
    /* background-color: #16437E; */
    /* color: #ffffff; */
  }
  
  .button-2:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
  }
  
  .button-2:disabled { 
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
  }


  .react-multi-carousel-dot-list {
    display: none !important; /* Hides the dot indicators */
  }
  
  .react-multi-carousel-item {
    display: flex;
  }
  
  .react-multi-carousel-item img {
    border-radius: 50%;
  }
  
  .react-multi-carousel-prev,
  .react-multi-carousel-next {
    display: none !important; /* Hides the navigation arrows */
  }
  



  
.intro {
  margin: 6rem 0;
  overflow-x: hidden;
  background-image: url("./assets/bluebackground.jpg");
  overflow-x: hidden;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;
  position: relative;
  padding-bottom:9rem;
}

.intro-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 15, 15, 0.4);
  z-index: 1;
}

@media (max-width: 1065px) {
  .intro {
    margin: 5.7rem 0;
  }
  .visual-head {
    text-align: center;
  }
}

.intro-svg img {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}




.collab-logo img{
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}


.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.pagination-button, .pagination-arrow {
  border: none;
  background-color: white;
  color: black;
  padding: 0.75rem;
  font-size: 1rem;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.pagination-button:hover, .pagination-arrow:hover {
  background-color: #f0f0f0;
}

.pagination-button.active {
  background-color: #5f82e6;
  color: white;
}

.pagination-arrow:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}



.service-card:hover {
  background-color: #6c63ff; /* Change background color on hover */
}

.service-card:hover h6,
.service-card:hover p,
.service-card:hover a {
  color: #fff; /* Change text and link color on hover */
}

.service-card:hover img {
  border-color: #fff; /* Change border color of image on hover */
}
/* faqs */
/* Centering the FAQ section */
.faqs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  /* background-color: #f8f9fa; Light background */
}

.faqs {
  width: 50%; /* Adjust the width to control how wide the FAQ section is */
  /* background-color: white; */
  padding: 30px;
  border-radius: 10px;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
}

@media only screen and (max-width: 1131px) {
  .faqs{
    width: 80%;
  }
}

/* Styling for individual FAQ items */
.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 24px 0;
}

.faq-item:last-child {
  border-bottom: none; /* Remove border from last item */
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  color: #333;
}

.faq-question h4 {
  margin: 0;
  font-size: 13px;
  font-weight:700;
}

.faq-answer {
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.5;
  color: #535353;
}

/* Icon styling */
.faq-question svg {
  font-size: 18px;
  color: #666;
}



.team-member {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.team-image {
  transition: transform 0.5s ease;
}

.team-member:hover .team-image {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.team-member:hover .overlay {
  opacity: 1;
}

.overlay a {
  color: white;
  transition: transform 0.3s ease;
}

.overlay a:hover {
  transform: scale(1.2);
}








/* portfolio */
.portfolio-tabs .portfolio-item {
  cursor: pointer;
  text-align: center;
  margin-bottom: 20px;
}

.portfolio-tabs .portfolio-item img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.portfolio-tabs .portfolio-item:hover img {
  transform: scale(1.05);
}

.portfolio-tabs .portfolio-item p {
  margin-top: 10px;
  font-weight: bold;
}



/* Style for the phone screen */
/* Style for the phone screen */
.phone-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* border: 2px solid #000; */
  border-radius: 30px;
  width: 400px;
  height: 800px; /* Typical phone screen height */
  background-color: #f5f5f5;
  margin: 0 auto;
}

.phone-screen {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px; /* To mimic the phone screen */
  /* background-color: #000; */
}

.phone-screen img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Keeps the aspect ratio and prevents stretching */
}

.service-image {
  width: 100%;
  height: 800px; /* Set a fixed height for consistency */
  object-fit: cover; /* Ensures the image covers the area without distorting */
  border-radius: 4px;
}
